




import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Route } from 'vue-router';

import { NavGuard } from '../utils/decorators';
import { user } from '../store/modules/user-module';

@Component
export default class Invitation extends Vue {
  @NavGuard
  public async beforeRouteEnter(to: Route, from: Route, next: Function) {
    const { id } = to.query;
    if (Array.isArray(id) || !id) return next('/404');

    let that: Vue = undefined as any;
    next((vm: any) => (that = vm.$app.loader(vm.$t('sign-in-dialog'))));

    await user.invitation({ id });
    const stopLoader = () => that.$app.loader();
    next('/on-boarding', stopLoader, stopLoader);
  }
}
